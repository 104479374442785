import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../../assets/images/initiatives-meta.png'
import CogIcon from '../../assets/images/home/icons/cog.svg'
import PuzzleIcon from '../../assets/images/home/icons/puzzle.svg'
import StopwatchIcon from '../../assets/images/home/icons/stopwatch.svg'
import tag from '../../assets/images/tags/initiatives-tag.svg'
import Layout from '../../components/Layout'
import IllustrationBlock from '../../components/blocks/IllustrationBlock'
import CTABlock from '../../components/blocks/CTABlock'
import ColumnsBlock from '../../components/blocks/ColumnsBlock'
import HeroBlock from '../../components/blocks/HeroBlock'
import LeadBlock from '../../components/blocks/LeadBlock'
import MosaicBlock from '../../components/blocks/MosaicBlock'
import QuoteBlock from '../../components/blocks/QuoteBlock'
import { FeaturedBlogArticles } from '../../components/changelog/FeaturedBlogArticles'
import Box from '../../components/primitives/Box'
import Blurb from '../../components/system/Blurb'
import { responsiveScale } from '../../styles/helpers'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/backgrounds/initiatives.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 99)
      }
    }
    lead: file(relativePath: { eq: "images/lead/initiative.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1200)
      }
    }
    mosaicLeft: file(
      relativePath: { eq: "images/mosaic/initiatives-all-mosaic.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicRight: file(
      relativePath: { eq: "images/mosaic/initiatives-timeline-mosaic.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicLeft2: file(
      relativePath: { eq: "images/mosaic/initiative-forecast.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
  }
`

const InitiativesPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Initiatives"
      variant="dark"
      isNew
      description="Swarmia gives you the visibility you need to keep strategic engineering initiatives moving."
      metaImage={MetaImage}
    >
      <HeroBlock
        tag={tag}
        title={<>Lead strategic engineering initiatives with confidence</>}
        content="Never lose sight of the big picture. Swarmia gives you the visibility you need to keep engineering initiatives moving."
        backgroundImage={getImage(data.backgroundImage)!}
      />
      <LeadBlock
        heading={<>Take the surprises out of&nbsp;feature&nbsp;delivery</>}
        content="Swarmia allows you to stay on top of ongoing initiatives so you can quickly spot at-risk work and course correct before it’s too late."
      />
      <Box>
        <IllustrationBlock
          paddingTop={responsiveScale(32)}
          paddingBottom={responsiveScale(32)}
          image={getImage(data.lead)!}
          noPlaceHolder
          rounded
          shadow
        />
      </Box>
      <ColumnsBlock
        paddingTop={responsiveScale(32)}
        paddingBottom={responsiveScale(64)}
        columns={[
          <Blurb
            key="1"
            title="Track cross-team work"
            text="Eliminate cross-team bottlenecks and understand the exact contributions of different people and teams."
            icon={PuzzleIcon}
          />,
          <Blurb
            key="2"
            title="Spot at-risk initiatives"
            text="Quickly identify initiatives that are falling behind from their planned schedule so you can get them back on track."
            icon={StopwatchIcon}
          />,
          <Blurb
            key="3"
            title="Reduce manual effort"
            text="With Swarmia, the status of you initiatives is always up to date based on data from your issue tracker and source code hosting."
            icon={CogIcon}
          />,
        ]}
      />
      <QuoteBlock person="christian" />
      <LeadBlock
        heading="Gain full visibility into the status and progress of cross-team initiatives"
        content="Get the exact information you need to give the business reliable updates about ongoing initiatives. Use detailed progress data to unblock teams that are stuck."
      />
      <MosaicBlock
        title={<>See all your initiatives at&nbsp;a&nbsp;glance</>}
        content={
          <>
            Get all the information you need for board updates or company
            all-hands meetings in one place, without the manual work of pulling
            data from different places.
            <br />
            <br />
            Quickly spot the initiatives that are moving along nicely and the
            ones that need your attention.
          </>
        }
        image={getImage(data.mosaicLeft)!}
      />
      <MosaicBlock
        title="Keep the work moving"
        content="Drill down to the work items that belong to each initiative to identify issues that are taking longer than expected. Then, proactively offer support to the teams that need it."
        image={getImage(data.mosaicRight)!}
        imageAlign="right"
      />
      <MosaicBlock
        title={<>Estimate the end date of&nbsp;your&nbsp;initiatives</>}
        content="Use historical data to forecast when a project will be completed. Save your forecasts, share them with stakeholders, and adjust projected end dates as you learn more."
        image={getImage(data.mosaicLeft2)!}
      />
      <QuoteBlock person="tim2" />
      <Box paddingBottom={responsiveScale(64)}>
        <CTABlock
          title={
            <>Ready to take control of your&nbsp;strategic&nbsp;initiatives?</>
          }
        />
        <FeaturedBlogArticles
          heading="More from the swarmia blog"
          articles={[
            '/blog/cross-team-initiatives/',
            '/blog/why-product-teams-should-plan-together/',
            '/blog/measuring-software-development-productivity/',
          ]}
          newStyles
        />
      </Box>
    </Layout>
  )
}

export default InitiativesPage
